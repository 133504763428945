<script>function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
// @ts-check
/** @typedef {{component():*, path: string, isLayout: false, param: false}} Decorator */
/** @typedef {ClientNode | Decorator} LayoutOrDecorator */
/**
 * @typedef {Object} Context
 * @prop {ClientNode} layout
 * @prop {ClientNode} component
 * @prop {LayoutOrDecorator} child
 * @prop {SvelteComponent} ComponentFile
 * @prop {HTMLElement} parentNode
 * */

import { suppressComponentWarnings } from './utils';
import Noop from './decorators/Noop.svelte';
import '../typedef.js';
import { getContext, setContext, tick } from 'svelte';
import { writable } from 'svelte/store';
import { metatags, afterPageLoad } from './helpers.js';
import { route, routes, rootContext } from './store';
import { handleScroll } from './utils';
import { onPageLoaded } from './utils/onPageLoaded.js';

/** @type {LayoutOrDecorator[]} */
export var nodes = [];
export var scoped = {};
export var decorator = undefined;

/** @type {LayoutOrDecorator} */
var node = null;
var remainingNodes = null;
var scopedSync = {};
var parentNode;
var invalidate = 1;
var context = writable(null);
/** @type {import("svelte/store").Writable<Context>} */
var parentContext = getContext('routify') || rootContext;
var setParentNode = el => parentNode = el.parentNode;
setContext('routify', context);
var lastNodes = [];
$: if (lastNodes !== nodes) {
  lastNodes = nodes;
  [node, ...remainingNodes] = [...nodes];
  node['api'].reset = () => invalidate++;
}

/**  @param {LayoutOrDecorator} node */
function setComponent(node) {
  var PendingComponent = node.component();
  if (PendingComponent instanceof Promise) PendingComponent.then(onComponentLoaded);else onComponentLoaded(PendingComponent);
}
$: setComponent(node);

/** @param {SvelteComponent} componentFile */
function onComponentLoaded(componentFile) {
  scopedSync = _extends({}, scoped);

  // we have to proxy remaining nodes through ctx (instead of props) or route changes get propagated
  // to leaf layouts of to-be-destroyed-layouts
  var ctx = _extends({}, $context, {
    nodes: remainingNodes,
    decorator: decorator || Noop,
    layout: node.isLayout ? node : $parentContext.layout,
    component: node,
    route: $route,
    routes: $routes,
    componentFile,
    parentNode: parentNode || $parentContext.parentNode
  });
  context.set(ctx);
  $parentContext.child = node;
  if (remainingNodes.length === 0) onLastComponentLoaded();
}
function onLastComponentLoaded() {
  return _onLastComponentLoaded.apply(this, arguments);
}
/**  @param {ClientNode} layout */
function _onLastComponentLoaded() {
  _onLastComponentLoaded = _asyncToGenerator(function* () {
    yield new Promise(resolve => setTimeout(resolve));
    var isOnCurrentRoute = $context.component.path === $route.path; //maybe we're getting redirected

    // Let everyone know the last child has rendered
    if (!window['routify'].stopAutoReady && isOnCurrentRoute) onPageLoaded({
      page: $context.component,
      metatags,
      afterPageLoad,
      parentNode
    });
  });
  return _onLastComponentLoaded.apply(this, arguments);
}
function getID(_ref) {
  var {
    meta,
    path,
    param,
    params
  } = _ref;
  return JSON.stringify({
    path,
    invalidate,
    param: (meta['param-is-page'] || meta['slug-is-page']) && param,
    queryParams: meta['query-params-is-page'] && params
  });
}
$: id = $context && invalidate && getID($context.component);
$: $context && suppressComponentWarnings($context, tick);</script>

{#if $context}
  {#each [$context] as { component, componentFile, decorator, nodes } (id)}
    <svelte:component this={decorator} {scoped}>
      <svelte:component
        this={componentFile}
        let:scoped={scopeToChild}
        let:decorator
        {scoped}
        {scopedSync}
        {...node.param || {}}
      >
        {#if component && nodes.length}
          <svelte:self
            {decorator}
            {nodes}
            scoped={{ ...scoped, ...scopeToChild }}
          />
        {/if}
      </svelte:component>
    </svelte:component>
  {/each}
{/if}
<!-- get the parent element for scroll and transitions -->

{#if !parentNode}
  <div style="display: contents" use:setParentNode />
{/if}
