
/**
 * @roxi/routify 2.18.11
 * File generated Fri Nov 17 2023 02:07:14 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.11"
export const __timestamp = "2023-11-17T02:07:14.906Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => import('../src/pages/_fallback.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/account-details/index",
              "id": "_auth_accountDetails_index",
              "component": () => import('../src/pages/auth/account-details/index.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/account-details"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/faqs/index",
              "id": "_auth_faqs_index",
              "component": () => import('../src/pages/auth/faqs/index.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/faqs"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/history/index",
              "id": "_auth_history_index",
              "component": () => import('../src/pages/auth/history/index.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/history"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/kyc/index",
              "id": "_auth_kyc_index",
              "component": () => import('../src/pages/auth/kyc/index.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/kyc"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/auth/wallets/index",
              "id": "_auth_wallets_index",
              "component": () => import('../src/pages/auth/wallets/index.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/wallets"
        }
      ],
      "isLayout": true,
      "path": "/auth",
      "id": "_auth__layout",
      "component": () => import('../src/pages/auth/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/callback/index",
          "id": "_callback_index",
          "component": () => import('../src/pages/callback/index.svelte').then(m => m.default)
        }
      ],
      "path": "/callback"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/confirm/:id",
          "id": "_confirm__id",
          "component": () => import('../src/pages/confirm/[id].svelte').then(m => m.default)
        }
      ],
      "path": "/confirm"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/email-verify/index",
          "id": "_emailVerify_index",
          "component": () => import('../src/pages/email-verify/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/email-verify/success/index",
              "id": "_emailVerify_success_index",
              "component": () => import('../src/pages/email-verify/success/index.svelte').then(m => m.default)
            }
          ],
          "path": "/email-verify/success"
        }
      ],
      "path": "/email-verify"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/kyc/error/index",
              "id": "_kyc_error_index",
              "component": () => import('../src/pages/kyc/error/index.svelte').then(m => m.default)
            }
          ],
          "path": "/kyc/error"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/kyc/success/index",
              "id": "_kyc_success_index",
              "component": () => import('../src/pages/kyc/success/index.svelte').then(m => m.default)
            }
          ],
          "path": "/kyc/success"
        }
      ],
      "path": "/kyc"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/logout/index",
          "id": "_logout_index",
          "component": () => import('../src/pages/logout/index.svelte').then(m => m.default)
        }
      ],
      "path": "/logout"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/maintenance/index",
          "id": "_maintenance_index",
          "component": () => import('../src/pages/maintenance/index.svelte').then(m => m.default)
        }
      ],
      "path": "/maintenance"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/onboard/index",
          "id": "_onboard_index",
          "component": () => import('../src/pages/onboard/index.svelte').then(m => m.default)
        }
      ],
      "path": "/onboard"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/pagenotfound/index",
          "id": "_pagenotfound_index",
          "component": () => import('../src/pages/pagenotfound/index.svelte').then(m => m.default)
        }
      ],
      "path": "/pagenotfound"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/signin/index",
          "id": "_signin_index",
          "component": () => import('../src/pages/signin/index.svelte').then(m => m.default)
        }
      ],
      "path": "/signin"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/support/index",
          "id": "_support_index",
          "component": () => import('../src/pages/support/index.svelte').then(m => m.default)
        }
      ],
      "path": "/support"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/terms/index",
          "id": "_terms_index",
          "component": () => import('../src/pages/terms/index.svelte').then(m => m.default)
        }
      ],
      "path": "/terms"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

