<script context="module">function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { writable, derived, get } from 'svelte/store';

/** config */
var iframeNum = 2;
var defaults = {
  validFor: 60,
  timeout: 5000,
  gracePeriod: 1000
};

/** stores and subscriptions */
var queue = writable([]);
var actives = derived(queue, q => q.slice(0, iframeNum));
actives.subscribe(actives => actives.forEach(_ref => {
  var {
    options
  } = _ref;
  setTimeout(() => removeFromQueue(options.prefetch), options.timeout);
}));

/**
 * @param {string} path
 * @param {defaults} options
 */
export function prefetch(path, options) {
  if (options === void 0) {
    options = {};
  }
  prefetch.id = prefetch.id || 1;
  path = path.href || path;
  options = _extends({}, defaults, options);
  options.prefetch = prefetch.id++;

  //don't prefetch within prefetch or SSR
  if (window.routify.prefetched || navigator.userAgent.match('jsdom')) return false;

  // add to queue
  queue.update(q => {
    if (!q.some(e => e.options.path === path)) q.push({
      url: path + "__[[routify_url_options]]__" + encodeURIComponent(JSON.stringify(options)),
      options
    });
    return q;
  });
}

/**
 * @param {number|MessageEvent} idOrEvent
 */
function removeFromQueue(idOrEvent) {
  var id = idOrEvent.data ? idOrEvent.data.prefetchId : idOrEvent;
  if (!id) return null;
  var entry = get(queue).find(entry => entry && entry.options.prefetch == id);
  // removeFromQueue is called by both eventListener and timeout,
  // but we can only remove the item once
  if (entry) {
    var {
      gracePeriod
    } = entry.options;
    var gracePromise = new Promise(resolve => setTimeout(resolve, gracePeriod));
    var idlePromise = new Promise(resolve => {
      window.requestIdleCallback ? window.requestIdleCallback(resolve) : setTimeout(resolve, gracePeriod + 1000);
    });
    Promise.all([gracePromise, idlePromise]).then(() => {
      queue.update(q => q.filter(q => q.options.prefetch != id));
    });
  }
}

// Listen to message from child window
addEventListener('message', removeFromQueue, false);</script>

<div id="__routify_iframes" style="display: none">
  {#each $actives as prefetch (prefetch.options.prefetch)}
    <iframe src={prefetch.url} frameborder="0" title="routify prefetcher" />
  {/each}
</div>
